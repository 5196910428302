<template>
    <div class="checkbox_wrapper" @click="click">
        <div class="checkbox">
            <img src="/assets/img/check.svg" v-show="modelValue" />
        </div>
        <p class="checkbox_label">{{label}}</p>
    </div>
</template>

<script lang="ts">

    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'VCheckBox',
        props: ["modelValue", "label"],
        methods: {
            click: function () {
                this.$emit('update:modelValue', !this.modelValue);
            }
        }
    });
</script>

<style scoped>
    .checkbox_wrapper{
        display: flex;
        cursor: pointer;
    }

    .checkbox {
        width: 16px;
        height: 16px;
        border: 1.5px solid #D5D5D5;
        border-radius: 4px;
        position: relative;
        background-color: white;
        -webkit-touch-callout: none;
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .checkbox img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }

    .checkbox_label {
        margin: 1px 0px 0px 7px;
    }
</style>
