<template>
    <div class="element_wrapper">
        <div class="position-relative">
            <span :class="errorText?.length > 0 ? 'textarea_label textarea_label_error' : 'textarea_label'" v-if="label?.length > 0">
                {{ label }}
            </span>

            <div :class="errorText?.length > 0 ? 'textarea_wrapper error' : isFocused ? 'textarea_wrapper active' : 'textarea_wrapper'">
                <textarea :placeholder="placeholder" :style="{ 'height': `${textareaHeight}px` }" class="textarea" v-model="data" @keyup="updateData($event)" @focus="focus" @blur="blur" :disabled="isDisabled" />
            </div>
            
        </div>

        <span class="span_error" v-show="errorText?.length > 0">{{ $t(errorText ? errorText : "empty_string")}}</span>
    </div>
</template>

<script lang="ts">

    import { defineComponent } from 'vue';

    interface ComponentModel {
        isFocused: boolean,
        isDisabled: boolean,
        textareaHeight: string,
        data: any,
    }

    export default defineComponent({
        name: 'VTextarea',
        props: ["modelValue", "label", "placeholder", "height", "errorText",  "disabled"],
        data(): ComponentModel {
            return {
                isFocused: false,
                isDisabled: false,
                textareaHeight: "18",
                data: null
            }
        },
        methods: {
            updateData: function () {
                if (this.isDisabled) return;

                this.$emit('update:modelValue', this.data);
            },
            focus: function () {
                if (this.isDisabled) return;
                this.isFocused = true;
                this.$emit('focus');
            },
            blur: function () {
                if (this.isDisabled) return;
                this.isFocused = false;
            }

        },
        watch: {
            'modelValue': function (newVal) {
                this.data = newVal;
            }
        },
        mounted() {
            this.isDisabled = this.disabled != null && this.disabled != undefined && this.disabled == true;
            this.textareaHeight = this.height != null && this.height != undefined ? this.height : "100";

            this.data = this.modelValue;
        }
    });
</script>

<style scoped>
    .element_wrapper {
        position: relative;
        width: 100%;
        min-height: 100px;
    }

    .textarea_wrapper {
        overflow: hidden;
        border-radius: 12px;
        border: 1.5px solid #D5D5D5;
    }

    .active {
        border: 1.5px solid #21A7D1 !important;
    }

    .textarea {
        border: unset;
        padding: 7px 12px;
        font-size: 16px;
        width: 100%;
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
        resize: none;
        display: flex;
    }

    .textarea:focus-visible {
        outline: unset;
        border: unset; 
    }

    .textarea_label {
        position: absolute;
        font-size: 12px;
        top: -8px;
        background-color: white;
        padding: 0px 4px;
        z-index: 2;
        left: 13px;
        border-radius: 8px;
    }

    .span_error {
        font-size: 11px;
        color: red;
        margin-left: 15px;
    }

    .error {
        border: 1.5px solid red !important;
    }

    .textarea_label_error {
        color: red;
    }

    textarea:disabled {
        cursor: no-drop;
        opacity: 0.5;
    }
</style>
