<template>
    <div class="datatable_wrapper">
        <div class="datatable" v-show="showItems.length > 0">
            <div class="datatable_th_row">
                <div class="datatable_th_cell" v-for="(colum, index) in tableColums" :key="index">
                    <div class="flex">
                        {{$t(colum.title)}}
                        <div class="arrow_wrapper" v-if="colum.isSortable" @click="sortTable(index)">
                            <img :src="colum.isSortedDesc === undefined || colum.isSortedDesc ? '/assets/img/sort-arrow-up.svg' : '/assets/img/sort-arrow-up-black.svg'">
                            <img :src="colum.isSortedDesc === undefined || !colum.isSortedDesc ? '/assets/img/sort-arrow-down.svg' : '/assets/img/sort-arrow-down-black.svg'" class="datatable_sort_button_right">
                        </div>
                    </div>
                </div>
            </div>
            <div class="datatable_body">
                <div class="datatable_row" v-for="(itemRow, index) in showItems" :key="index" @click="clickOnRow($event, itemRow.id)">
                    <div class="datatable_cell" v-for="(item, index) in itemRow.data" :key="index" v-html="applyType(item, tableColums[index])"></div>
                </div>

            </div>
        </div>
        <div class="datatable_footer" v-show="showItems.length > 0">
            <div>
                <div v-show="pageCount > 1" class="flex mt-5">
                    <button @click="previousPage" :class="cuurentPage != 0 ? 'datatable_footer_button mr-2' : 'datatable_footer_button datatable_footer_button_disable mr-2'"><img class="datatable_footer_button_img_back" src="/assets/img/arrow-left.svg"><span>{{$t('datatable.back')}}</span></button>
                    <button :class="cuurentPage == 0 ? 'datatable_footer_page_button datatable_footer_page_button_active' : 'datatable_footer_page_button'" v-show="!isSearchPageShow" @click="selectPage(0)">1</button>

                    <v-input v-model="searchPage" :label="'1 - ' + pageCount" inputType="number" v-show="isSearchPageShow" search @update:modelValue="selectPage(searchPage - 1)"></v-input>

                    <div v-show="!isSearchPageShow" class="datatable_footer_page_button_wrapper">
                        <button :class="page == -1 ? 'datatable_footer_page_button datatable_footer_page_button_empty' : cuurentPage == page - 1 ? 'datatable_footer_page_button datatable_footer_page_button_active' : 'datatable_footer_page_button'" v-for="(page, index) in paginationButtons" :key="index" @click="selectPage(page - 1)">{{page != -1 ? page : ''}}<img v-if="page == -1" src="/assets/img/ellipsis.svg"></button>
                    </div>

                    <div class="datatable_footer_page_button_mobile_wrapper">
                        <button :class="cuurentPage == page - 1 ? 'datatable_footer_page_button datatable_footer_page_button_active' : 'datatable_footer_page_button'" v-for="(page, index) in paginationMobileButtons" :key="index" @click="selectPage(page - 1)">{{ page }}</button>
                    </div>

                    <button :class="pageCount - 1 == cuurentPage ? 'datatable_footer_page_button datatable_footer_page_button_active' : 'datatable_footer_page_button'" v-show="!isSearchPageShow" @click="selectPage(pageCount - 1)">{{pageCount}}</button>
                    <button @click="nextPage" :class="cuurentPage != pageCount - 1 ? 'datatable_footer_button ml-2' : 'datatable_footer_button datatable_footer_button_disable ml-2'"><span>{{$t('datatable.next')}}</span><img class="datatable_footer_button_img_next" src="/assets/img/arrow-right.svg"></button>
                </div>
            </div>
            <div class="limit_wrapper mt-5" v-show="tableData.length > 10">
                <p class="datatable_footer_select_text">{{$t('datatable.show')}}</p>
                <v-select v-model="showLimit" class="limit" :items="limits" @update:modelValue="changeLimit" openUp></v-select>
            </div>
        </div>

        <div v-show="showItems.length == 0" :class="emptyTable">
            {{$t('datatable.no_data_available')}}
        </div>
    </div> 
</template>

<script lang="ts">

    import { defineComponent } from 'vue';
    import { TableRow, TableColum } from '../../models/Table'
    //import { getFormatedDate, getContractStatus, addPhoneMask, getFreezeStatus, getOrderStatus, getRefundOrderStatus, getMailingStatus, getTrainingBlockStatus, getTrainingStatus } from '../../services/helper'

    interface TableColumWrapper extends TableColum {
        isSortedDesc: boolean | undefined
    }

    interface TableLimit {
        id: number,
        text: string
    }

    interface ComponentModel {
        showLimit: TableLimit,
        tableColums: TableColumWrapper[],
        tableData: TableRow[],
        showItems: TableRow[],
        limits: TableLimit[],
        cuurentPage: number,
        isSearchPageShow: boolean,
        pageCount: number,
        paginationButtons: number[],
        paginationMobileButtons: number[],
        searchPage: number,
        emptyTable: string
    }

    export default defineComponent({
        name: 'VDataTable',
        props: ["columTemplates", "data", "emptyTableClass"],
        emits: ["clickOnRow", "clickOnButton"],
        data(): ComponentModel {
            return {
                showLimit: { id: 20, text: "20" },
                cuurentPage: 0,
                showItems: [],
                tableColums: [],
                tableData: [],
                isSearchPageShow: false,
                pageCount: 0,
                searchPage: 0,
                paginationButtons: [],
                paginationMobileButtons: [],
                limits: [
                    { id: 10, text: "10" },
                    { id: 20, text: "20" },
                    { id: 50, text: "50" }
                ],
                emptyTable: ""
            }
        },
        methods: {
            sortTable: function (index) {
                this.tableColums.forEach((x, elIndex) => { if (elIndex != index) x.isSortedDesc = undefined });

                if (this.tableColums[index].isSortedDesc === undefined) {
                    this.tableColums[index].isSortedDesc = false;
                } else {
                    this.tableColums[index].isSortedDesc = !this.tableColums[index].isSortedDesc
                }

                if (!this.tableColums[index].isSortedDesc) {

                    this.tableData = this.tableData.sort(function (a, b) {
                        if (a.data[index] > b.data[index]) {
                            return 1;
                        }
                        if (a.data[index] < b.data[index]) {
                            return -1;
                        }
                        return 0;
                    });
                } else {
                    this.tableData = this.tableData.sort(function (a, b) {
                        if (a.data[index] < b.data[index]) {
                            return 1;
                        }
                        if (a.data[index] > b.data[index]) {
                            return -1;
                        }
                        return 0;
                    });
                }

                this.cuurentPage = 0;
                this.showItems = this.tableData.slice(this.cuurentPage, this.showLimit.id);
            },
            changeLimit: function () {
                this.cuurentPage = 0;
                this.showItems = this.tableData.slice(this.cuurentPage, this.showLimit.id);

                this.pageCount = Math.ceil(this.tableData.length / this.showLimit.id);

                this.generatePaginationButtons();
                this.generatePaginationMoblileButtons();
            },
            nextPage: function () {
                this.cuurentPage++;
                if (this.cuurentPage > this.pageCount - 1) this.cuurentPage = this.pageCount - 1;

                this.generatePaginationButtons();
                this.generatePaginationMoblileButtons();

                this.showItems = this.tableData.slice(this.cuurentPage * this.showLimit.id, (this.cuurentPage + 1) * this.showLimit.id);
            },
            previousPage: function () {
                this.cuurentPage--;
                if (this.cuurentPage < 0) this.cuurentPage = 0;

                this.generatePaginationButtons();
                this.generatePaginationMoblileButtons();

                this.showItems = this.tableData.slice(this.cuurentPage * this.showLimit.id, (this.cuurentPage + 1) * this.showLimit.id);
            },
            selectPage: function (page: number) {
                if (page < 0) {
                    this.searchPage = this.cuurentPage + 1;
                    this.isSearchPageShow = true;

                    return;
                }

                this.isSearchPageShow = false;

                this.cuurentPage = page;
                if (this.cuurentPage < 0) this.cuurentPage = 0;
                if (this.cuurentPage > this.pageCount - 1) this.cuurentPage = this.pageCount - 1;

                this.generatePaginationButtons();
                this.generatePaginationMoblileButtons();

                this.showItems = this.tableData.slice(this.cuurentPage * this.showLimit.id, (this.cuurentPage + 1) * this.showLimit.id);
            },
            generatePaginationButtons: function () {
                this.paginationButtons = [];

                if (this.pageCount == 2) return;

                if (this.pageCount >= 3 && this.pageCount <= 6) {
                    for (let i = 2; i < this.pageCount; i++) this.paginationButtons.push(i);

                    return;
                }

                if (this.cuurentPage <= 2) {
                    this.paginationButtons = [2, 3, 4, 5, -1];

                    return;
                }

                if (this.cuurentPage >= this.pageCount - 3) {
                    this.paginationButtons = [-1, this.pageCount - 4, this.pageCount - 3, this.pageCount - 2, this.pageCount - 1];

                    return;
                }

                this.paginationButtons = [-1, this.cuurentPage, this.cuurentPage + 1, this.cuurentPage + 2, -1];
            },
            generatePaginationMoblileButtons: function () {
                this.paginationMobileButtons = [];

                if (this.pageCount == 2) return;


                if (this.cuurentPage == 0) {
                    this.paginationMobileButtons = [2];

                    return;
                }

                if (this.cuurentPage == this.pageCount - 1) {
                    this.paginationMobileButtons = [this.cuurentPage];

                    return;
                }

                this.paginationMobileButtons = [this.cuurentPage + 1];
            },
            applyType: function (item: string, tableColums: TableColumWrapper) {
                //if (tableColums.type == "date") {
                //    return tableColums?.template?.replaceAll('%data%', getFormatedDate(item, 'DD.MM.yyyy') || '-').replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "datetime") {
                //    return tableColums?.template?.replaceAll('%data%', getFormatedDate(item, 'DD.MM.yyyy HH:mm') || '-').replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "sex") {
                //    return tableColums?.template?.replaceAll('%data%', item == "true" ? this.$t('datatable.male_short') : this.$t('datatable.female_short')).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "bool") {
                //    return tableColums?.template?.replaceAll('%data%', item == "true" ? this.$t('datatable.yes') : this.$t('datatable.no')).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "contract_status") {
                //    return tableColums?.template?.replaceAll('%data%', this.$t('contract_status.' + getContractStatus(parseInt(item)))).replaceAll('%color%', getContractStatus(parseInt(item))).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "freeze_status") {
                //    return tableColums?.template?.replaceAll('%data%', this.$t('freeze_status.' + getFreezeStatus(parseInt(item)))).replaceAll('%color%', getFreezeStatus(parseInt(item))).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "order_status") {
                //    return tableColums?.template?.replaceAll('%data%', this.$t('order_status.' + getOrderStatus(parseInt(item)))).replaceAll('%color%', getOrderStatus(parseInt(item))).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "refund_order_status") {
                //    return tableColums?.template?.replaceAll('%data%', this.$t('refund_order_status.' + getRefundOrderStatus(parseInt(item)))).replaceAll('%color%', getRefundOrderStatus(parseInt(item))).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "training_block_status") {
                //    return tableColums?.template?.replaceAll('%color%', getTrainingBlockStatus(parseInt(item))).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "training_status") {
                //    return tableColums?.template?.replaceAll('%color%', getTrainingStatus(parseInt(item))).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "mailing_status") {
                //    return tableColums?.template?.replaceAll('%data%', this.$t('mailing_status.' + getMailingStatus(parseInt(item)))).replaceAll('%color%', getMailingStatus(parseInt(item))).replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "phone") {
                //    return tableColums?.template?.replaceAll('%data%', addPhoneMask(item) || '').replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "duration") {
                //    let duration: number = parseInt(item);
                //    return tableColums?.template?.replaceAll('%data%', Math.floor(duration / 60) == 0 ? `${Math.floor(duration % 60)} ${this.$t('datatable.m')}` : `${Math.floor(duration / 60)} ${this.$t('datatable.h')} ${Math.floor(duration % 60)} ${this.$t('datatable.m')}` || '').replaceAll('%title%', this.$t(tableColums.title));
                //} else if (tableColums.type == "payments_processing_provider") {
                //   return tableColums?.template?.replaceAll('%data%', this.$t(item)).replaceAll('%title%', this.$t(tableColums.title));
                //} else {
                //    return tableColums?.template?.replaceAll('%data%', item || '').replaceAll('%title%', this.$t(tableColums.title));

                //}

                return tableColums?.template?.replaceAll('%data%', item || '').replaceAll('%title%', this.$t(tableColums.title));
            },
            clickOnRow: function (event: any, id: string) {
                if (event.target.tagName === "A") {
                    event.preventDefault();
                    if (event.target.getAttribute("param-href")) return this.$router.push(event.target.getAttribute("param-href"));
                } if (event.target.tagName === "BUTTON") {
                    this.$emit('clickOnButton', event.target.getAttribute("param-id"), event.target.getAttribute("param-action"));
                } else {
                    this.$emit('clickOnRow', id);
                }

            }
        },
        watch: {
            'data': function (newVal) {
                this.tableColums = this.columTemplates;
                for (let i = 0; i < this.tableColums.length; i++) {
                    this.tableColums[i].isSortedDesc = undefined;
                }

                this.tableData = newVal;

                this.cuurentPage = 0;
                this.showItems = newVal.slice(this.cuurentPage, this.showLimit.id);
                this.pageCount = Math.ceil(this.tableData.length / this.showLimit.id);

                this.generatePaginationButtons();
                this.generatePaginationMoblileButtons();
            }
        },
        beforeMount() {
            this.tableColums = this.columTemplates;
            this.tableData = this.data;
            this.showItems = this.tableData.slice(this.cuurentPage, this.showLimit.id);
            this.emptyTable = this.emptyTableClass ? this.emptyTableClass : "datatable_empty"
            this.pageCount = Math.ceil(this.tableData.length / this.showLimit.id);

            this.generatePaginationButtons();
            this.generatePaginationMoblileButtons();
        }
    });
</script>

<style scoped>
    .datatable_wrapper {
        width: 100%;
        position: relative;
        padding-bottom: 2px;
    }

    .datatable {
        display: table;
        width: 100%
    }

    .datatable_th_row {
        display: table-row;
    }

    .datatable_row {
        display: table-row;
    }

    .datatable_body {
        display: table-row-group;
    }

    .datatable_th_cell {
        display: table-cell;
        border-bottom: 1.5px solid #D5D5D5;
        font-weight: bold;
        padding: 8px;
    }

    .datatable_cell {
        display: table-cell;
        border-bottom: 1.5px solid #D5D5D5;
        padding: 8px;
    }

    .datatable_sort_button_right {
        margin-left: -10px;
    }

    .datatable_footer {
        display: flex;
        justify-content: space-between;
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }

    .datatable_footer_select_text {
        color: #65656F;
        margin: 8px 75px 8px 0px;
    }

    .datatable_footer_button {
        padding: 10px 20px 10px 20px;
        border-radius: 12px;
        background-color: #C7E9F3;
        border: none;
        cursor: pointer;
        display: flex;
    }

    .datatable_footer_button_img_back {
        margin-right: 7px;
        margin-top: 1px;
    }

    .datatable_footer_button_img_next {
        margin-left: 7px;
        margin-top: 1px;
    }

    .datatable_footer_button_disable {
        opacity: 0.7;
    }

    .datatable_footer_page_button {
        padding: 10px 0px 10px 0px;
        border-radius: 12px;
        background-color: #f2f2f2;
        border: none;
        cursor: pointer;
        margin: 0px 5px;
        min-width: 35px;
    }

    .datatable_footer_page_button_active {
        background-color: #21A7D1;
        color: white;
    }

    .datatable_footer_page_button_empty {
        background-color: unset;
        position: relative;
        height: 24px;
    }

    .datatable_footer_page_button_empty img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 5px;
    }

    .datatable_footer_page_button_mobile_wrapper {
        display: none;
    }

    .datatable_empty {
        text-align: center;
        margin-top: 30px;
        color: #65656F;
    }

    .limit_wrapper {
        display: flex;
    }

    .limit {
        position: absolute;
        right: 5px;
    }

    .datatable_row:hover {
        background-color: #f6f7f8;
    }

    .arrow_wrapper{
        height: 19px;
        display: flex;
        cursor: pointer;
    }

    @media(max-width: 960px) {
        .datatable_footer_button {
            padding: 10px 14px;
        }

            .datatable_footer_button span {
                display: none;
            }

        .datatable_footer_button_img_back {
            margin-right: 0px;
        }

        .datatable_footer_button_img_next {
            margin-left: 0px;
        }
    }

    @media(max-width: 800px) {
        .datatable_footer_page_button_wrapper {
            display: none;
        }

        .datatable_footer_page_button_mobile_wrapper {
            display: flex;
        }
    }

    @media(max-width: 600px) {
        .datatable_footer_select_text {
            margin: 0px 75px 8px 10px
        }
    }


    @media(max-width: 500px) {
        .limit_wrapper {
            display: none;
        }

        .datatable_footer {
            justify-content: center;
        }

        .datatable {
            display: flex;
        }

        .datatable_body {
            width: 100%;
        }

        .datatable_th_row {
            display: none;
        }

        .datatable_row {
            display: block;
            border: 1.5px solid #D5D5D5;
            border-radius: 16px;
            padding: 16px;
            margin-bottom: 10px;
        }

        .datatable_cell {
            display: flex;
            border-bottom: none;
            padding: 0px;
        }
    }
</style>
