<template>
    <div class="position-relative select_component" v-click-outside="onClickOutside"  ref="select_relative">
        <div :class="isDisabled ? 'disabled' : errorText?.length > 0 ? selectClass + ' error' : selectClass" @click="clickOnSelect($event)">
            <div :class="selectedOption.length > 0 ?  errorText?.length > 0 ? 'select_label_wrapper select_label_wrapper_error select_label_show' : 'select_label_wrapper select_label_show' : 'select_label_wrapper select_label_hide'">
                <p>{{ isLabelTranslated ? $t(label ? label: "empty_string") : label }}</p>
            </div>

            <span :class="selectedOption.length > 0 ? 'select_label' : 'select_label select_label_text'">
                <span v-for="(item, index) in selectText" :key="item.text" :class="{ label_span_with_ellipsis: withEllipsis}">{{ item.isTranslated ? $t(item.text ? item.text : "empty_string") : item.text }}{{index == selectText.length - 1 ? "" : ", "}}</span>
            </span>

            <img v-if="isClearable && selectedOption.length > 0" class="select_cancel" src="/assets/img/close.svg" />
            <img class="select_arrow" :src="isOpen ? '/assets/img/arrow-up.svg' : '/assets/img/arrow-down.svg'" />
        </div>
        <div v-show="isOpen" :class="selectOptionsClass">
            <div class="select_options" ref="select_options">
                <div v-for="option in options" class="select_option_wrapper" :key="option.id">
                    <div class="group_name" v-if="option.showGroupName">
                        {{ option.isTranslated ? $t(option.groupName ? option.groupName : "empty_string") : option.groupName }}
                    </div>
                    <div :class="option.class" @click="select(option)">
                        <v-checkbox v-model="checkboxList[option.id]" v-if="isMultiple"></v-checkbox>
                        <p>{{ option.isTranslated ? $t(option.text ? option.text: "empty_string") : option.text }}</p>
                    </div>
                </div>
            </div>

        </div>

        <span class="span_error" v-show="errorText?.length > 0">{{ $t(errorText ? errorText : "empty_string")}}</span>

    </div>
</template>

<script lang="ts">

    import { defineComponent, nextTick } from 'vue';

    interface ComponentModel {
        options: any[],
        isOpen: boolean,
        selectedOption: any[],
        isSelectedFirst: boolean,
        isMultiple: boolean,
        isClearable: boolean,
        isOpenUp: boolean,
        isDisabled: boolean,
        selectText: any[],
        selectClass: string,
        selectOptionsClass: string,
        checkboxList: any[],
        groupName: string,
        isLabelTranslated: boolean,
        withEllipsis: boolean
    }

    export default defineComponent({
        name: 'VSelect',
        props: ["modelValue", "label", "items", "selectedFirst", "multiple", "clearable", "openUp", "errorText", "disabled", "translatedLabel", "labelWithEllipsis"],
        data(): ComponentModel {
            return {
                options: [],
                isOpen: false,
                selectedOption: [],
                isSelectedFirst: false,
                isMultiple: false,
                isClearable: false,
                isOpenUp: false,
                isDisabled: false,
                selectText: [],
                selectClass: "",
                selectOptionsClass: "",
                checkboxList: [],
                groupName: "",
                isLabelTranslated: true,
                withEllipsis: false
            }
        },
        methods: {
            select: function (item: any) {
                if (this.isDisabled) return;
                if (item.disabled) return;

                if (this.isMultiple) {
                    const index = this.selectedOption.findIndex(x => x.id == item.id);

                    if (index != -1) {
                        this.checkboxList[item.id] = false;
                        this.selectedOption.splice(index, 1);
                    } else {
                        this.checkboxList[item.id] = true;
                        this.selectedOption.push(item);
                    }
                    
                    this.$emit('update:modelValue', this.selectedOption);
                } else {
                    this.selectedOption = [];
                    this.selectedOption.push(item);
                    this.$emit('update:modelValue', item);
                    this.isOpen = false;
                    this.selectClass = this.isClearable ? 'select_with_cancel_button' : 'select_without_cancel_button';
                    this.selectOptionsClass = '';
                }

                for (let option of this.options) option.class = this.addOptionClass(option)

                this.generateSelectText();
            },
            generateSelectText: function () {
                this.selectText = [];
                if (this.selectedOption.length == 0) {
                    this.selectText.push({ text: this.label, isTranslated: this.isLabelTranslated });
                } else if (this.selectedOption.length == 1) {
                    this.selectText.push({ text: this.selectedOption[0].text, isTranslated: this.selectedOption[0].isTranslated });
                } else if (this.selectedOption.length > 1) {
                    this.selectText = this.selectedOption.map(x => { return { text: x.text, isTranslated: x.isTranslated } });
                } 
            },
            clickOnSelect: function (e: any) {
                if (this.isDisabled) return;

                if (e.target.classList.contains("select_cancel")) {
                    this.selectedOption = [];

                    if (this.isMultiple) {
                        this.checkboxList.forEach((_element, index) => { this.checkboxList[index] = false });
                        this.$emit('update:modelValue', []);
                    } else {
                        this.$emit('update:modelValue', null);
                    }

                    for (let option of this.options) option.class = this.addOptionClass(option)

                    this.generateSelectText();

                    return;
                }

                this.isOpen = !this.isOpen;

                if (this.isOpen) {
                    let select_relative: any = this.$refs.select_relative;
                    let select_options: any = this.$refs.select_options;

                    select_options.parentNode.style.width = `${select_relative.getBoundingClientRect().width}px`;

                    if (window.innerHeight - e.y - 232 > 0 && !this.isOpenUp) {
                        this.selectClass = this.isClearable ? 'select_with_cancel_button select_active_down' : 'select_without_cancel_button select_active_down';
                        this.selectOptionsClass = 'select_options_wrapper select_options_wrapper_down';
                        

                        if(this.errorText){
                             select_options.parentNode.style.top = `${select_relative.getBoundingClientRect().bottom + 3 - select_relative.children[2].getBoundingClientRect().height}px`;
                        }else{
                             select_options.parentNode.style.top = `${select_relative.getBoundingClientRect().bottom - 1}px`;
                        }
                       
                    } else {
                        this.selectClass = this.isClearable ? 'select_with_cancel_button select_active_up' : 'select_without_cancel_button select_active_up';
                        this.selectOptionsClass = 'select_options_wrapper select_options_wrapper_up';
                        nextTick(() => { select_options.parentNode.style.top = `${select_relative.getBoundingClientRect().top - select_options.parentNode.getBoundingClientRect().height + 1}px`});                        
                    }

                    nextTick(() => {
                        let selectItem: any = this.findSelectItem();

                        if (selectItem) {

                            select_options.scrollTop = 0;
                            select_options.scrollTo({
                                top: selectItem.getBoundingClientRect().top - select_options.getBoundingClientRect().top,
                                left: 0,
                                behavior: 'smooth'
                            })
                        } });
                   
                    
                } else {
                    this.selectClass = this.isClearable ? 'select_with_cancel_button' : 'select_without_cancel_button';
                    this.selectOptionsClass = '';
                }
            },
            findSelectItem: function () {
                let select_options: any = this.$refs.select_options;

                let reuslt = null;

                for (let option of select_options.children) {
                    let checkingElementIndex = option.children.length == 1 ? 0 : 1;
                    if (!option.children[checkingElementIndex].classList.contains("select_option_selected")) continue;

                    reuslt = option;
                    break;
                }

                return reuslt;
            },
            onClickOutside: function () {
                this.isOpen = false;
                this.selectClass = this.isClearable ? 'select_with_cancel_button' : 'select_without_cancel_button';
                this.selectOptionsClass = '';
            },
            addOptionClass: function (item: any) {
                if (item.disabled && !item.groupName) {
                    return 'select_option select_option_disabled'
                } else if (item.disabled && item.groupName) {
                    return 'ml-4 select_option select_option_disabled'
                } else if (!item.disabled && this.selectedOption.find(x => x.id == item.id) && !item.groupName) {
                    return 'select_option select_option_selected'
                } else if (!item.disabled && this.selectedOption.find(x => x.id == item.id) && item.groupName) {
                    return 'ml-4 select_option select_option_selected'
                } else if (!item.disabled && !this.selectedOption.find(x => x.id == item.id) && !item.groupName) {
                    return 'select_option'
                } else if (!item.disabled && !this.selectedOption.find(x => x.id == item.id) && item.groupName) {
                    return 'ml-4 select_option'
                }
            },
            checkIfShowGroupName: function (item: any) {
                if (!item.groupName) return false;
                if (item.groupName == this.groupName) return false;

                this.groupName = item.groupName;
                return true;
            },
        },
        watch: {
            'items': {
                handler: function(newVal) {
                    if (!this.modelValue) {
                        this.selectedOption = [];

                        if (this.isSelectedFirst && newVal.length > 0) this.selectedOption.push(newVal[0]);
                    }

                    this.options = newVal.map(x => {
                        return {
                            id: x.id,
                            text: x.text,
                            isTranslated: x.isTranslated,
                            disabled: x.disabled,
                            groupName: x.groupName,
                            class: this.addOptionClass(x),
                            showGroupName: this.checkIfShowGroupName(x)
                        }
                    });

                    this.generateSelectText();

                    this.checkboxList = [];

                    for (let i = 0; i < newVal.length; i++) {
                        this.checkboxList[newVal[i].id] = this.selectedOption.findIndex(x => x.id == newVal[i].id) != -1;
                    }
                },
                deep: true 
            },
            'modelValue': function (newVal) {
                if (this.isMultiple) {
                    this.selectedOption = newVal;

                } else {
                    this.selectedOption = [];
                    if (newVal) this.selectedOption.push(newVal);
                }

                for (let i = 0; i < this.options.length; i++) {
                    this.options[i].class = this.addOptionClass({ id: this.options[i].id, text: this.options[i].text, isTranslated: this.options[i].isTranslated, disabled: this.options[i].disabled, groupName: this.options[i].groupName });
                    this.checkboxList[this.options[i].id] = this.selectedOption.findIndex(x => x.id == this.options[i].id) != -1; 
                }

                this.generateSelectText();
            }, 
            'isOpen': function (newVal) {
                if (!newVal) this.$emit('optionsClosed');
            }
        },
        mounted() {
            this.isDisabled = this.disabled != null && this.disabled != undefined && this.disabled == true;
            this.isClearable = this.clearable != null && this.clearable != undefined && !this.isDisabled;
            this.isSelectedFirst = this.selectedFirst != null && this.selectedFirst != undefined;
            this.isMultiple = this.multiple != null && this.multiple != undefined;
            this.withEllipsis = this.labelWithEllipsis != null && this.labelWithEllipsis != undefined;

            if (this.translatedLabel != null && this.disabled != undefined) {
                this.isLabelTranslated = this.translatedLabel;
            }

            this.isOpenUp = this.openUp != null && this.openUp != undefined;

            if (!this.modelValue) {
                if (this.isSelectedFirst && this.items.length > 0) {
                    this.selectedOption.push(this.items[0]);
                }
            } else {
                if (this.isMultiple) {
                    this.selectedOption = this.modelValue;
                } else {
                    this.selectedOption.push(this.modelValue);
                }
            }

            this.options = this.items.map(x => {
                return {
                    id: x.id,
                    text: x.text,
                    isTranslated: x.isTranslated,
                    disabled: x.disabled,
                    groupName: x.groupName,
                    class: this.addOptionClass(x),
                    showGroupName: this.checkIfShowGroupName(x)
                }
            });

            this.selectClass = this.isClearable ? 'select_with_cancel_button' : 'select_without_cancel_button';

            this.generateSelectText();

            for (let i = 0; i < this.items.length; i++) {
                this.checkboxList[this.items[i].id] = this.selectedOption.findIndex(x => x.id == this.items[i].id) != -1; 
            }
        }
    });
</script>

<style scoped>
    .select_without_cancel_button {
        border: 1.5px solid #D5D5D5;
        padding: 8px 30px 7px 12px;
        border-radius: 12px;
        font-size: 16px;
        position: relative;
        display: flex;
        cursor: pointer;
        min-height: 18px
    }

    .select_with_cancel_button {
        border: 1.5px solid #D5D5D5;
        padding: 8px 50px 7px 12px;
        border-radius: 12px;
        font-size: 16px;
        position: relative;
        display: flex;
        cursor: pointer;
        min-height: 18px
    }

    .select_arrow {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }

    .select_label {
        overflow: hidden;
    }

    .select_label_text {
        color: #65656F;
        animation: label 0.3s;
    }

    .select_label_wrapper {
        position: absolute;
        font-size: 12px;
        top: -8px;
        background-color: white;
        padding: 0px 4px;
        z-index: 2;
    }

    .select_label_wrapper p {
        margin: 0px;
    }

    .select_label_show {
        display: block;
        animation: show 0.3s;
    }

    .select_label_hide {
        display: none;
        animation: hide 0.3s;
    }

    .select_options_wrapper {
        border: 1.5px solid #D5D5D5;
        padding: 0px;
        font-size: 16px;
        position: fixed;
        cursor: pointer;
        z-index: 3;
        background-color: white;
        overflow: hidden;
        min-height: 12px;
        box-sizing: border-box;
    }

    .select_options_wrapper_down {
        border-radius: 0px 0px 12px 12px;
    }

    .select_options_wrapper_up {
        border-radius: 12px 12px 0px 0px;
        margin-bottom: 34px;
    }

    .select_options {
        overflow: auto;
        max-height: 240px;
    }

    .select_option_selected {
        background-color: #efefef;
    }

    .select_active_down {
        border-radius: 12px 12px 0px 0px;
        border-bottom: 0px;
    }

    .select_active_up {
        border-radius: 0px 0px 12px 12px;
    }

    .select_option {
        border-bottom: 1px solid #D5D5D5;
        padding: 7px 12px;
        display: flex;
        min-height: 16px;
    }

    .select_option:hover {
        background-color: #efefef;
    }

    .select_option_wrapper:first-child .select_option {
        border-bottom: 1px solid #D5D5D5;
        padding: 10px 12px 7px 12px;
    }

    .select_option_wrapper:last-child .select_option {
        border-bottom: unset;
        padding: 7px 12px 10px 12px;
    }

    .select_option p {
        margin: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .select_cancel {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        height: 12px;
    }

    .select_option_disabled {
        opacity: 0.5;
        cursor: no-drop;
    }

    .disabled {
        background-color: #fcfcfc;
        cursor: no-drop;
        border: 1.5px solid #d5d5d563;
        padding: 8px 30px 7px 12px;
        border-radius: 12px;
        font-size: 16px;
        position: relative;
        display: flex;
        min-height: 18px
    }

    .disabled .select_label{
        opacity:0.5;
    }

    .disabled .select_arrow {
        opacity: 0.5;
    }

    .span_error {
        font-size: 11px;
        color: red;
        margin-left: 15px;
    }

    .error {
        border: 1.5px solid red !important;
    }

    .select_label_wrapper_error {
        color: red;
    }

    .group_name {
        padding: 7px 12px;
        display: flex;
        min-height: 16px;
        border-bottom: 1px solid #D5D5D5;
        cursor: default !important;
        background-color: #f9f9f9;
        border-top: 1px solid #D5D5D5;
        transform: scaleY(1.03);
        font-weight: 600;
    }

    .label_span_with_ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
    }

    @keyframes show {
        0% {
            font-size: 16px;
            top: 0px;
            color: #65656F;
            padding: 0px 0px;
        }
        99% {
            padding: 0px 4px;
        }
        100% {
            font-size: 12px;
            top: -8px;
            color: black;
        }
    }

    @keyframes hide {
        0% {
            font-size: 12px;
            top: -8px;
            color: black;
            padding: 0px 4px;
            display: block;
        }

        99% {
            padding: 0px 0px;
            color: #65656F;
            top: 8px;
            font-size: 16px;
        }

        100% {
            font-size: 16px;
            color: #65656F;
            display: none;
        }
    }

    @keyframes label {
        0% {
            display: block;
            opacity: 0;
        }
        99% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

</style>
