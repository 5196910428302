<template>
    <div class="alert_wrapper" v-if="modelValue">
        <div class="alert_body" v-click-outside="close">
            <div v-show="title" class="alert_title">{{ title ? $t(title) : $t("empty_string") }}</div>
            <div class="alert_text" v-if="text">{{ isUntranslatableЕext ? text : $t(text) }}</div>
            <div class="alert_text" v-if="html" v-html="html"></div>
            <div class="alert_buttons_wrapper">
                <button class="primary_button" @click="$emit('success')">{{successButtonText || 'ОК'}}</button>
                <button class="secondary_button button_space" v-if="cancelButtonText" @click="$emit('cancel')">{{cancelButtonText}}</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    interface ComponentModel {
        isUntranslatableЕext: boolean
    }

    export default defineComponent({
        name: 'VAlert',
        props: ["modelValue", "title", "text", "html", "successButtonText", "cancelButtonText", "doNotTranslated"],
        data(): ComponentModel {
            return {
                isUntranslatableЕext: false
            }
        },
        methods: {
            close: function () {
                this.$emit('update:modelValue', false);
            },
            checkPressedButton: function (event: any) {
                if (event.keyCode == 27) this.close();
            }
        },
        mounted() {
            this.isUntranslatableЕext = this.doNotTranslated != null && this.doNotTranslated != undefined && this.doNotTranslated == true;

            window.addEventListener('keyup', this.checkPressedButton);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.checkPressedButton);
        }
    });
</script>

<style scoped>
    .alert_wrapper {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: #b5b4b466;
        z-index: 99999;
    }

    .alert_body {
        background-color: white;
        position: absolute;
        top: 50%;
        max-width: 480px;
        width: max-content;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 24px;
        overflow: hidden;
        animation: showAlert 0.6s linear;
    }

    .alert_title {
        font-size: 17px;
        letter-spacing: 1px;
        font-weight: 600;
        background-color: #f6f7f8;
        padding: 16px 24px;
    }

    .alert_text {
        margin-top: 12px;
        line-height: 22px;
        padding: 0px 24px;
    }

    .alert_buttons_wrapper {
        margin-top: 12px;
        display: flex;
        padding: 0px 24px;
        margin-bottom: 8px;
        justify-content: center;
    }

    .button_space{
        margin-left: 16px;
    }

    @media(max-width: 500px) {
        .alert_body {
            max-width: 98%;
        }

        .alert_buttons_wrapper {
            display: block;
            margin-bottom: 16px;
        }

        .button_space {
            margin-left: 0px;
        }
    }

    @keyframes showAlert {
        0% {
            top: -100%;
        }

        100% {
            top: 50%;
        }
    }
</style>