import { createWebHistory, createRouter, RouteRecordRaw } from "vue-router";
import { store } from '../store'
//import { getPermissions } from '../services/helper';
import QR from "@/components/QR/QR.vue";

import ViewPage403 from "@/components/ServicePages/Page403.vue";
import ViewPage404 from "@/components/ServicePages/Page404.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "QR",
        component: QR
    },
    {
        path: "/forbidden",
        name: "ViewPage403",
        component: ViewPage403
    },
    {
        path: "/:pathMatch(.*)*",
        name: "ViewPage404",
        component: ViewPage404
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    },
});

router.beforeEach(async (to, from, next) => {
    const url = to.path.split('/');

    store.commit('SET_CURRENT_URL', to.path);
    store.commit('SET_ISSHOWINTERNALSERVERERROR', false);

    //if (url[1] == "contract" || url[1] == "order" || url[1] == "refundorder") {
    //    store.commit('SET_ACTIVE_MENU', "/clients");
    //} else {
    //    store.commit('SET_ACTIVE_MENU', "/" + url[1]);
    //}

    //store.commit('SET_ACTIVE_SUBMENU', url.length > 2 ? "/" + url[2] : "");

    //await getPermissions(url[1]);

    next();
})

export default router;