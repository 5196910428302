import { createI18n } from 'vue-i18n';
import { nextTick } from "vue";
import ru from './ru.json';

export const i18n = createI18n({
    legacy: false,
    locale: 'ru',
    fallbackLocale: 'ru',
    messages: { ru }
})

export const getCurrentLocal = function () {
    return i18n.global.locale.value;
}

export const switchLanguage = async function (newLocale) {
    if (!newLocale || newLocale == 'null') return;
    await loadLocaleMessages(newLocale);
    i18n.global.locale.value = newLocale;
    localStorage.setItem("user-locale", newLocale);
}

export const loadLocaleMessages = async function (locale) {
    if (!locale || locale == 'null') return;
    if (!i18n.global.availableLocales.includes(locale)) {
        const messages = await import(`@/i18n/${locale}.json`);
        i18n.global.setLocaleMessage(locale, messages.default);
    }

    return await nextTick();
}