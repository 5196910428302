import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelRadio as _vModelRadio, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-de438cae"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "radio" }
const _hoisted_3 = { class: "radio_active" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.radioList, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(_ctx.radioWrapperClass),
        key: index,
        onClick: ($event: any) => (_ctx.selectRadio(item))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
            [_vShow, item == _ctx.data]
          ])
        ]),
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          value: item,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event)),
          hidden: ""
        }, null, 8, _hoisted_4), [
          [_vModelRadio, _ctx.data]
        ]),
        _createElementVNode("label", _hoisted_5, _toDisplayString(item?.label ? _ctx.$t(item?.label) : _ctx.$t("empty_string")), 1)
      ], 10, _hoisted_1))
    }), 128))
  ]))
}