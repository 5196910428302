<template>
    <div class="flex" ref="menu">
        <div v-show="isShowLeftArrow" class="scroll_button scroll_button_left" @click="clickLeftButton"><img src="/assets/img/arrow-left.svg" /></div>
        <div ref="menuItems" class="menu_wrapper">
            <div class="flex">
                <div :class="tab.url == modelValue.url ? 'menu_item active' : 'menu_item'" v-for="tab in items" :key="tab.url" @click="click(tab)">{{$t(tab.text)}}</div>
            </div>
        </div>
        <div v-show="isShowRightArrow" class="scroll_button scroll_button_right" @click="clickRightButton"><img src="/assets/img/arrow-right.svg" /></div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';

    interface ComponentModel {
        isShowLeftArrow: boolean,
        isShowRightArrow: boolean,
        timer: number | null,
        isTouchStarted: boolean,
        touchStartCoordinates: number
    }

    export default defineComponent({
        name: 'VScrollableMenu',
        data(): ComponentModel {
            return {
                isShowLeftArrow: false,
                isShowRightArrow: false,
                timer: null,
                isTouchStarted: false,
                touchStartCoordinates: 0
            }
        },
        props: ["modelValue", "items"],
        methods: {
            click: function (tab: any) {
                this.$emit('update:modelValue', tab);
            },
            onWindowResize: function () {
                if (this.timer) {
                    clearTimeout(this.timer);
                }

                this.timer = setTimeout(() => {
                    this.calculatingSize();
                }, 200)

                return;
            },
            calculatingSize: function () {
                const menu: any = this.$refs.menu;
                const items: any = this.$refs.menuItems;

                if (window.screen.width <= 500) {
                    this.isShowLeftArrow = false;
                    this.isShowRightArrow = false;

                    return;
                }

                if (menu.getBoundingClientRect().right - items.getBoundingClientRect().right > 54) {
                    this.isShowLeftArrow = false;
                    this.isShowRightArrow = false;
                } else {
                    this.isShowLeftArrow = items.scrollLeft != 0;
                    this.isShowRightArrow = true;
                }
            },
            getStartCoordinates: function (event: any) {
                if (!this.isTouchStarted) {
                    this.isTouchStarted = true;
                    this.touchStartCoordinates = event.touches[0].clientX;
                }               
            },
            swipeAction: function (event: any) {
                const items: any = this.$refs.menuItems;

                if (!event.target.classList.contains('menu_item')) return;

                if (this.touchStartCoordinates - event.touches[0].clientX > 30) {
                    items.scrollLeft += 30;
                    this.touchStartCoordinates = event.touches[0].clientX;
                } else if (this.touchStartCoordinates - event.touches[0].clientX < -30) {
                    items.scrollLeft -= 30;
                    this.touchStartCoordinates = event.touches[0].clientX;
                }
            },
            endSwipeAction: function () {
                this.isTouchStarted = false;
            },
            clickLeftButton: function () {
                const items: any = this.$refs.menuItems;

                this.isShowRightArrow = true;

                let menuLeftCoord = items.getBoundingClientRect().left;

                let coord: number | null = null;
                for (let i = items.children[0].children.length - 1; i >= 0; i--) {
                    if (menuLeftCoord > items.children[0].children[i].getBoundingClientRect().right) {
                        coord = items.children[0].children[i].getBoundingClientRect().left;
                        if (i == 0) {
                            this.isShowLeftArrow = false;
                        } else {
                            this.isShowLeftArrow = true;
                        }
                        break;
                    }
                }

                if (!coord) {
                    this.isShowLeftArrow = false;

                    items.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                } else {
                    items.scrollTo({
                        top: 0,
                        left: items.scrollLeft - (menuLeftCoord - coord) + 10,
                        behavior: 'smooth'
                    })
                }

                
            },
            clickRightButton: function () {
                const items: any = this.$refs.menuItems;

                let menuRightCoord = items.getBoundingClientRect().right;

                this.isShowLeftArrow = true;

                let coord: number | null = null;
                for (let i = 0; i < items.children[0].children.length; i++) {
                    if (menuRightCoord < items.children[0].children[i].getBoundingClientRect().left) {
                        coord = items.children[0].children[i].getBoundingClientRect().right;
                        if (i == items.children[0].children.length - 1) {
                            this.isShowRightArrow = false;
                        } else {
                            this.isShowRightArrow = true;
                        }

                        break;
                    }
                }

                if (!coord) {
                    this.isShowRightArrow = false;

                    items.scrollTo({
                        top: 0,
                        left: menuRightCoord,
                        behavior: 'smooth'
                    })
                } else {
                    items.scrollTo({
                        top: 0,
                        left: coord - menuRightCoord + items.scrollLeft,
                        behavior: 'smooth'
                    })
                }
            }
        },
        watch: {
            'items': function () {
                this.calculatingSize();
            },
        },
        mounted() {
            window.addEventListener('resize', this.onWindowResize);
            window.addEventListener('touchstart', this.getStartCoordinates);
            window.addEventListener('touchmove', this.swipeAction);
            window.addEventListener('touchend', this.endSwipeAction);
            window.addEventListener('touchcancel', this.endSwipeAction);
            this.calculatingSize();
        },
        beforeUnmount() {
            window.removeEventListener('resize', this.onWindowResize);
            window.removeEventListener('touchstart', this.getStartCoordinates);
            window.removeEventListener('touchmove', this.swipeAction);
            window.removeEventListener('touchend', this.endSwipeAction);
            window.removeEventListener('touchcancel', this.endSwipeAction);
        }
    });
</script>

<style scoped>
    .menu_wrapper {
        overflow: hidden
    }

    .menu_item {
        display: flex;
        padding: 0px 16px 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-bottom: 1.5px solid #D5D5D5;
        cursor: pointer;
        white-space: nowrap;
        user-select: none;
    }

    .active {
        color: #21A7D1;
        border-bottom: 1.5px solid #21A7D1;
    }

    .scroll_button {
        cursor: pointer;
        display: flex;
        user-select: none;
    }

    .scroll_button img {
        height: 13px;
        margin-top: 5px;
        margin-inline: auto;
        user-select: none;
    }

    .scroll_button_left {
        margin-right: 18px;
    }

    .scroll_button_right {
        margin-left: 18px;
    }
</style>
