<div class="qr_wrapper">
    <div class="qr_body">
        <div class="qr_data">
            <img class="load_img" src="/assets/img/load-animation.svg" v-if="isLoad" />
            <p class="error_text" v-if="isShowError">{{ errorText }}</p>
            <qrcode-vue :value="qr" :size="300" level="L" v-if="isShowQR" />
        </div>
       
        <button class="primary_button mt-4 mb-0 w-100" :disabled="isButtonDisabled" @click="getQr">{{$t('qr.update')}}</button>
    </div>
</div>


