import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-686e4104"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox" }
const _hoisted_2 = { src: "/assets/img/check.svg" }
const _hoisted_3 = { class: "checkbox_label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "checkbox_wrapper",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("img", _hoisted_2, null, 512), [
        [_vShow, _ctx.modelValue]
      ])
    ]),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.label), 1)
  ]))
}