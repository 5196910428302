import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-04f4ae46"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "position-relative select_component",
  ref: "select_relative"
}
const _hoisted_2 = {
  key: 0,
  class: "select_cancel",
  src: "/assets/img/close.svg"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "select_options",
  ref: "select_options"
}
const _hoisted_5 = {
  key: 0,
  class: "group_name"
}
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.isDisabled ? 'disabled' : _ctx.errorText?.length > 0 ? _ctx.selectClass + ' error' : _ctx.selectClass),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickOnSelect($event)))
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.selectedOption.length > 0 ?  _ctx.errorText?.length > 0 ? 'select_label_wrapper select_label_wrapper_error select_label_show' : 'select_label_wrapper select_label_show' : 'select_label_wrapper select_label_hide')
      }, [
        _createElementVNode("p", null, _toDisplayString(_ctx.isLabelTranslated ? _ctx.$t(_ctx.label ? _ctx.label: "empty_string") : _ctx.label), 1)
      ], 2),
      _createElementVNode("span", {
        class: _normalizeClass(_ctx.selectedOption.length > 0 ? 'select_label' : 'select_label select_label_text')
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectText, (item, index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: item.text,
            class: _normalizeClass({ label_span_with_ellipsis: _ctx.withEllipsis})
          }, _toDisplayString(item.isTranslated ? _ctx.$t(item.text ? item.text : "empty_string") : item.text) + _toDisplayString(index == _ctx.selectText.length - 1 ? "" : ", "), 3))
        }), 128))
      ], 2),
      (_ctx.isClearable && _ctx.selectedOption.length > 0)
        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("img", {
        class: "select_arrow",
        src: _ctx.isOpen ? '/assets/img/arrow-up.svg' : '/assets/img/arrow-down.svg'
      }, null, 8, _hoisted_3)
    ], 2),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(_ctx.selectOptionsClass)
    }, [
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "select_option_wrapper",
            key: option.id
          }, [
            (option.showGroupName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(option.isTranslated ? _ctx.$t(option.groupName ? option.groupName : "empty_string") : option.groupName), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: _normalizeClass(option.class),
              onClick: ($event: any) => (_ctx.select(option))
            }, [
              (_ctx.isMultiple)
                ? (_openBlock(), _createBlock(_component_v_checkbox, {
                    key: 0,
                    modelValue: _ctx.checkboxList[option.id],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.checkboxList[option.id]) = $event)
                  }, null, 8, ["modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("", true),
              _createElementVNode("p", null, _toDisplayString(option.isTranslated ? _ctx.$t(option.text ? option.text: "empty_string") : option.text), 1)
            ], 10, _hoisted_6)
          ]))
        }), 128))
      ], 512)
    ], 2), [
      [_vShow, _ctx.isOpen]
    ]),
    _withDirectives(_createElementVNode("span", { class: "span_error" }, _toDisplayString(_ctx.$t(_ctx.errorText ? _ctx.errorText : "empty_string")), 513), [
      [_vShow, _ctx.errorText?.length > 0]
    ])
  ])), [
    [_directive_click_outside, _ctx.onClickOutside]
  ])
}