<template src="./qr.html"></template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { axiosRequests } from '../../services/axiosinstance';
    import { editUrl } from '../../services/helper';
    import { Routes } from '../../const';
    import { QR } from '../../models/QR'
    import QrcodeVue from 'qrcode.vue'

     interface ComponentModel {
         isShowQR: boolean,
         isShowError: boolean,
         errorText: string,
         qr: string,
         isButtonDisabled: boolean
         isLoad: boolean
     }

    export default defineComponent({
        name: 'QR',

        data(): ComponentModel {
            return {
                isShowQR: false,
                isShowError: false,
                errorText: "",
                qr: "",
                isButtonDisabled: false,
                isLoad: false
            }
        },
        methods: {
            getQr: async function () {
                this.isButtonDisabled = true;
                this.isLoad = true;
                this.isShowQR = false;
                this.isShowError = false;

                await axiosRequests.get(editUrl(Routes.getQR))
                    .then(
                        (data: QR) => {
                            if (data.isError) {
                                this.isShowError = true;
                                this.errorText = data.errorText;
                            } else {
                                this.isShowQR = true;
                                this.qr = data.qrString
                            }
                        }
                    )
                    .catch(
                        async (err: any) => {
                            if (err.status == 401) {
                                return;
                            } else if (err.status == 403) {
                                this.$router.push('/forbidden');
                            } else if (err.status == 400) {
                                this.$store.commit('SET_ADD_ERROR', err.data.text);
                                this.errorText = this.$t('qr.load_qr_error');
                                this.isShowError = true;
                            } else {
                                this.$store.commit('SET_ADD_ERROR', 'error.something_went_wrong');
                            }
                        }
                    )
                    .finally(() =>{
                        this.isButtonDisabled = false;
                        this.isLoad = false;
                    });
            },
           
        },
        components: { QrcodeVue },
        async beforeMount() {
            await this.getQr();
        },
    });
</script>

<style scoped>
    .qr_wrapper {
        position: fixed;
        top: 50px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .qr_body {
        background-color: white;
        width: 301px;
        border-radius: 24px;
        padding: 24px;
        height: 354px;
    }

    .qr_data {
        display: flex;
        flex-flow: column;
        height: 300px;
        justify-content: center;
        align-items: center;
    }

    .load_img {
        height: 120px;
        margin: auto;
    }

    .error_text {
        font-size: 19px;
        font-weight: bold;
        text-align: center;
        margin: 0px;
    }
</style>