<template>
    <div class="page_wrapper">
        <div class="text_wrapper">
            <p class="code">500</p>
            <p class="description">{{$t('service_page.something_went_wrong')}}</p>
        </div>

    </div>

</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    export default defineComponent({
        name: 'ViewPage500'
    });
</script>

<style scoped>
    .page_wrapper {
        background-color: white;
        border-radius: 24px;
        padding: 24px;
        height: calc(100vh - 145px);
        position: relative;
    }

    .text_wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .code {
        font-size: 136px;
        font-weight: bold;
        margin: 0px;
        text-align: center;
    }

    .description {
        font-size: 26px;
        margin: 0px;
        text-align: center;
    }

    @media(max-width: 500px) {
        .code {
            font-size: 95px;
        }

        .description {
            font-size: 18px;
        }
    }
</style>