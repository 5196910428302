import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5240ac3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "qr_wrapper" }
const _hoisted_2 = { class: "qr_body" }
const _hoisted_3 = { class: "qr_data" }
const _hoisted_4 = {
  key: 0,
  class: "load_img",
  src: "/assets/img/load-animation.svg"
}
const _hoisted_5 = {
  key: 1,
  class: "error_text"
}
const _hoisted_6 = ["disabled"]

export function render(_ctx, _cache) {
  const _component_qrcode_vue = _resolveComponent("qrcode-vue")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isLoad)
          ? (_openBlock(), _createElementBlock("img", _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.isShowError)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.errorText), 1))
          : _createCommentVNode("", true),
        (_ctx.isShowQR)
          ? (_openBlock(), _createBlock(_component_qrcode_vue, {
              key: 2,
              value: _ctx.qr,
              size: 300,
              level: "L"
            }, null, 8, ["value"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("button", {
        class: "primary_button mt-4 mb-0 w-100",
        disabled: _ctx.isButtonDisabled,
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.getQr && _ctx.getQr(...args)))
      }, _toDisplayString(_ctx.$t('qr.update')), 9, _hoisted_6)
    ])
  ]))
}