import { InjectionKey } from 'vue'
import { createStore, Store } from 'vuex'
//import { HubConnectionBuilder } from '@microsoft/signalr'

export interface State {
    load: boolean,
    isAuth: boolean,
    isShowInternalServerError: boolean,
    errors: string[],
    successMessages: string[],
    currentUrl: string,
    activeMenu: string,
    activeSubmenu: string,
   // hubConnection: HubConnectionBuilder | undefined,
   // isChatReconnecting: boolean,
   // isChatConnected: boolean,
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        load: false,
        isAuth: false,
        isShowInternalServerError: false,
        errors: [],
        successMessages: [],
        currentUrl: '',
        activeMenu: '',
        activeSubmenu: '',    
        //hubConnection: undefined,
        //isChatReconnecting: false,
        //isChatConnected: false,
    },
    getters: {
        getLoad: state => { return state.load },
        getIsAuth: state => { return state.isAuth },
        getIsShowInternalServerError: state => { return state.isShowInternalServerError },
        getErrors: state => { return state.errors },
        getSuccessMessages: state => { return state.successMessages },
        getActiveMenu: state => { return state.activeMenu },
        getActiveSubmenu: state => { return state.activeSubmenu },
        getCurrentUrl: state => { return state.currentUrl },
        //getHubConnection: state => { return state.hubConnection },
        //getIsChatReconnecting: state => { return state.isChatReconnecting },
        //getIsChatConnected: state => { return state.isChatConnected },
    },
    mutations: {
        SET_LOAD: (state, data) => {
            state.load = data;
        },
        SET_ISAUTH: (state, data) => {
            state.isAuth = data;
        },
        SET_ISSHOWINTERNALSERVERERROR: (state, data) => {
            state.isShowInternalServerError = data;
        },
        SET_ADD_ERROR: (state, data) => {
            if (!state.errors.includes(data)) state.errors.push(data);
        },
        SET_REMOVE_ERROR: (state, data) => {
            const index = state.errors.findIndex(x => x == data);
            if (index != -1) state.errors.splice(index, 1);
        },
        SET_ADD_SUCCESS_MESSAGES: (state, data) => {
            if (!state.successMessages.includes(data)) state.successMessages.push(data);
        },
        SET_REMOVE_SUCCESS_MESSAGES: (state, data) => {
            const index = state.successMessages.findIndex(x => x == data);
            if (index != -1) state.successMessages.splice(index, 1);
        },
        SET_ACTIVE_MENU: (state, data) => {
            state.activeMenu = data;
        },
        SET_ACTIVE_SUBMENU: (state, data) => {
            state.activeSubmenu = data;
        },
        SET_CURRENT_URL: (state, data) => {
            state.currentUrl = data;
        },
        //SET_HUBCONNECTION: (state, data) => {
        //    state.hubConnection = data;
        //},
        //SET_IS_CHAT_RECONNECTING: (state, data) => {
        //    state.isChatReconnecting = data;
        //},
        //SET_IS_CHAT_CONNECTED: (state, data) => {
        //    state.isChatConnected = data;
        //},
    },
    actions: {
       
    },
});
