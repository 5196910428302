<template>
    <div class="input_wrapper">
        <div class="position-relative">
            <span :class="errorText?.length > 0 || error ? 'input_label input_label_error' : 'input_label'" v-if="labelText?.length > 0">
                {{ labelText }}
            </span>

            <input :type="inputType" :placeholder="placeholder" :style="{ 'height': `${inputHeight}px` }" :class="errorText?.length > 0 || error ? inputClass + ' error' : inputClass" v-model="data" :min="min" :max="max" :step="step" @keyup="updateData($event)" @change="updateDateData" @blur="blur" @focus="focus" :disabled="isDisabled"/>

            <img v-if="isClearable && data" :class="isSearch ? 'input_cancel_with_search' : 'input_cancel_without_search'" src="/assets/img/close.svg" @click="clear" />
            <img v-if="isPassword" :class="isSearch ? 'input_cancel_with_search' : 'input_cancel_without_search'" :src="inputType != 'text' ? '/assets/img/preview-open.svg' : '/assets/img/preview-close.svg'" @click="changeType" />
            <button v-if="isSearch" class="input_search_button" @click="updateDataByButton"><img src="/assets/img/search.svg" /></button>
        </div>
       
        <span class="span_error" v-show="errorText?.length > 0">{{ $t(errorText ? errorText : "empty_string")}}</span>
    </div>
</template>

<script lang="ts">

    import { defineComponent } from 'vue';

    interface ComponentModel {
        isClearable: boolean,
        isSearch: boolean,
        isPassword: boolean,
        isDisabled: boolean,
        inputClass: string,
        inputHeight: string,
        data: any,
        labelText: string
    }

    export default defineComponent({
        name: 'VInput',
        props: ["modelValue", "label", "inputType", "placeholder", "search", "clearable", "password", "height", "errorText", "error", "min", "max", "step", "disabled"],
        data(): ComponentModel {
            return {
                isClearable: false,
                isSearch: false,
                isPassword: false,
                isDisabled: false,
                inputHeight: "18",
                inputClass: "",
                data: null,
                labelText: ""
            }
        },
        methods: {
            updateData: function (event: any) {
                if (this.isDisabled) return;

                if (event.keyCode == 13) {
                    this.$emit('update:modelValue', this.data);
                    this.$emit('pressEnter');
                    return;
                } 

                if (!this.isSearch) this.$emit('update:modelValue', this.data);
            },
            updateDateData: function () {
                if (this.isDisabled) return;
                if (this.inputType == "date" || this.inputType == "datetime-local" || this.inputType == "number") this.$emit('update:modelValue', this.data);
            },
            clear: function () {
                if (this.isDisabled) return;
                this.data = null;
                this.$emit('update:modelValue', this.data);
            },
            updateDataByButton: function () {
                if (this.isDisabled) return;
                this.$emit('update:modelValue', this.data);
            },
            changeType: function () {
                if (this.isDisabled) return;
                this.$emit('changeType');
            },
            focus: function () {
                if (this.isDisabled) return;
                this.$emit('focus');
            },
            blur: function () {
                if (this.isDisabled) return;
                this.$emit('update:modelValue', this.data);
                this.$emit('blur');
            }
        },
        watch: {
            'modelValue': function (newVal) {
                this.data = newVal;
            },
            'label': function (newVal) {
                this.labelText = newVal;
            }
        },
        mounted() {
            this.isDisabled = this.disabled != null && this.disabled != undefined && this.disabled == true;
            this.isClearable = this.clearable != null && this.clearable != undefined && !this.isDisabled;
            this.isSearch = this.search != null && this.search != undefined && this.inputType != 'date' && this.inputType != 'datetime-local';
            this.isPassword = this.password != null && this.password != undefined;
            this.inputHeight = this.height != null && this.height != undefined ? this.height : "18";

            this.data = this.modelValue;
            this.labelText = this.label;

            this.inputClass = this.inputType != 'date' && this.inputType != 'datetime-local' ? "input" : "input input_date";

            if (this.inputType == 'color'){
                this.inputClass = "input input_color";
            } else if (this.isClearable && this.isSearch) {
                this.inputClass += " input_with_all_buttons";
            } else if (this.isClearable || this.isSearch || this.isPassword) {
                this.inputClass += " input_with_button";
            }
        }
    });
</script>

<style scoped>
    .input_wrapper {
        position: relative;
        width: 100%;
        min-height: 35px;
    }

    .input_cancel_with_search {
        position: absolute;
        right: 36px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        height: 16px;
    }

    .input_cancel_without_search {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        height: 16px;
    }

    .input {
        border: 1.5px solid #D5D5D5;
        padding: 7px 12px 7px 12px;
        border-radius: 12px;
        font-size: 16px;
        width: 100%;
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
    }

    .input_date {
        padding: 5px 30px 7px 12px;
    }

    .input_with_all_buttons {
        padding-right: 50px;
    }

    .input_with_button {
        padding-right: 33px;
    }

    .input:focus-visible {
        outline: unset;
        border: 1.5px solid #21A7D1;
    }

    .input_label {
        position: absolute;
        font-size: 12px;
        top: -8px;
        background-color: white;
        padding: 0px 4px;
        z-index: 2;
        left: 13px;
        border-radius: 8px;
    }

    .input_search_button {
        position: absolute;
        right: 7px;
        cursor: pointer;
        height: 24px;
        width: 24px;
        border: none;
        background-color: #f2f2f2;
        border-radius: 8px;
        top: 50%;
        transform: translateY(-50%);
    }

    .input_search_button img {
        height: 12px;
        margin-top: 4px;
    }

    .span_error {
        font-size: 11px;
        color: red;
        margin-left: 15px;
    }

    .error {
        border: 1.5px solid red !important;
    }

    .input_label_error{
        color: red;
    }

    input:disabled {
        cursor: no-drop;
        opacity: 0.5;
    }

    .input_color {
        cursor: pointer;
        padding: 5px 12px;
        background-color: white;
    }

</style>
