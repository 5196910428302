import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f6fb64b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element_wrapper" }
const _hoisted_2 = { class: "position-relative" }
const _hoisted_3 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.label?.length > 0)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.errorText?.length > 0 ? 'textarea_label textarea_label_error' : 'textarea_label')
          }, _toDisplayString(_ctx.label), 3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.errorText?.length > 0 ? 'textarea_wrapper error' : _ctx.isFocused ? 'textarea_wrapper active' : 'textarea_wrapper')
      }, [
        _withDirectives(_createElementVNode("textarea", {
          placeholder: _ctx.placeholder,
          style: _normalizeStyle({ 'height': `${_ctx.textareaHeight}px` }),
          class: "textarea",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event)),
          onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateData($event))),
          onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
          onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
          disabled: _ctx.isDisabled
        }, null, 44, _hoisted_3), [
          [_vModelText, _ctx.data]
        ])
      ], 2)
    ]),
    _withDirectives(_createElementVNode("span", { class: "span_error" }, _toDisplayString(_ctx.$t(_ctx.errorText ? _ctx.errorText : "empty_string")), 513), [
      [_vShow, _ctx.errorText?.length > 0]
    ])
  ]))
}